@import-normalize;

@font-face {
    font-family: 'geomanist';
    src: url('./fonts/geomanist/geomanist-book-webfont.woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'geomanist';
    src: url('./fonts/geomanist/geomanist-bold-webfont.ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: geomanist, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

.unselectable {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

@media print, (prefers-reduced-motion: reduce) {
    .animated {
        animation-duration: 1ms !important;
        transition-duration: 1ms !important;
        animation-iteration-count: 1 !important;
    }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

